<template>
	<section class="section">
		<div class="container">
			<h1>404</h1>
		</div>
	</section>
</template>

<script>
	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - 404`,
			};
		},
		mounted() {
			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		}
	};
</script>

<style scoped lang="scss"></style>
